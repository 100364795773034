/* eslint-disable @typescript-eslint/naming-convention */
import { Component, inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import {
  CLICK_FROM_NO_SEARCH_RESULTS,
  REQUEST_PRODUCT_CLICK,
} from 'src/app/presentation/shared/constants/mixpanel';
import { CategoryInterface } from 'src/app/presentation/shared/interfaces/product.interafce';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { RequestUnavailableProductDialogComponent } from './request-unavailable-product-dialog/request-unavailable-product-dialog.component';

@Component({
  selector: 'app-request-unavailable-product',
  templateUrl: './request-unavailable-product.component.html',
  styleUrls: ['./request-unavailable-product.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class RequestUnavailableProductComponent {
  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  @Input() categories!: CategoryInterface[];

  constructor(private dialog: MatDialog, private _siteTranslateService: SiteTranslateService) {}

  openRequestProductDialog() {
    const eventBody = {
      Language: 'ar',
      'Interface Version': 'v2',
      'Clicked From': CLICK_FROM_NO_SEARCH_RESULTS,
    };
    this._logMixpanelEventUseCase.execute({ eventName: REQUEST_PRODUCT_CLICK, payload: eventBody });
    const dialogRefOrderChat = this.dialog.open(RequestUnavailableProductDialogComponent, {
      width: '800px',
      height: '500px',
      data: {
        categories: this.categories,
        clickedFrom: CLICK_FROM_NO_SEARCH_RESULTS,
      },
      direction: this._siteTranslateService.currentDir,
    });
    dialogRefOrderChat.afterClosed().subscribe({ next: () => {} });
  }
}
